import React, { Fragment } from 'react';
import PackageList from '../layout/PackageList';
import Contact from '../layout/Contact';

function Home() {
  return (
    <Fragment>
      <PackageList />
      <br />
      <Contact />
    </Fragment>
  );
}

export default Home;
