import React, { Fragment } from 'react';

function Packages() {
  return (
    <Fragment>
      <div className="packages">
        <h2 className="center">Our Service Packages</h2>
        <div className="package-list">
          <div className="group-one">
            <div>
              <h3>Budget</h3>
              <ul>
                <li>
                  Historical Bookkeeping
                  <br />
                  <span>Client inputs data, we reconcile</span>
                </li>
                <li>Monthly bank, credit card and loan reconciliations</li>
                <li>Any needed adjusting journal entries (AJE’s)</li>
                <li>
                  Monthly financial statements
                  <br />
                  <span>Balance Sheets, Income Statements</span>
                </li>
                <li>
                  Unlimited emails
                  <br />
                  <span>
                    Response time will be charged towards billable hours
                  </span>
                </li>
              </ul>
              <ul>
                <li>
                  Max meeting support hours: <strong>.5</strong>
                </li>
                <li>
                  Max monthly billable hours: <strong>4</strong>
                </li>
              </ul>
            </div>
            <div>
              <h3>Basic</h3>
              <ul>
                <li>
                  <i>All Budget services plus:</i>
                </li>
                <li>Custom Financials</li>
              </ul>
              <ul>
                <li>
                  Max meeting support hours: <strong>1</strong>
                </li>
                <li>
                  Max monthly billable hours: <strong>5</strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="group-two">
            <div>
              <h3>Small Business</h3>
              <ul>
                <li>
                  Bookkeeping
                  <br />
                  <span>We input and reconcile client’s data</span>
                </li>
                <li>Monthly bank, credit card and loan reconciliations</li>
                <li>Any needed adjusting journal entries (AJE’s)</li>
                <li>
                  Monthly financial statements
                  <br />
                  <span>Balance Sheets, Income Statements</span>
                </li>
                <li>
                  Unlimited emails
                  <br />
                  <span>
                    Response time will be charged towards billable hours
                  </span>
                </li>
                <li>
                  Essential document storage, organizing &amp; digital filing
                </li>
                <li>City &amp; State tax returns plus audit support</li>
                <li>Coordinate with client’s CPA</li>
              </ul>
              <ul>
                <li>
                  Max meeting support hours: <strong>2</strong>
                </li>
                <li>
                  Max monthly billable hours: <strong>8</strong>
                </li>
              </ul>
            </div>
            <div>
              <h3>Growing Business - Our most popular plan!</h3>
              <ul>
                <li>
                  <i>All Small Business services plus:</i>
                </li>
                <li>Job Costing</li>
                <li>Accounts Payable &amp; Accounts Receivable</li>
                <li>Business License Management</li>
                <li>Payroll Management</li>
                <li>1099 Tracking and Processing</li>
              </ul>
              <ul>
                <li>
                  Max meeting support hours: <strong>3</strong>
                </li>
                <li>
                  Max monthly billable hours: <strong>15</strong>
                </li>
              </ul>
            </div>
            <div>
              <h3>Elite</h3>
              <ul>
                <li>
                  <i>All Growing Business services plus:</i>
                </li>
                <li>Industry Specific Reporting</li>
                <li>
                  Class location and tracking
                  <br />
                  <span>Additional fee possible</span>
                </li>
              </ul>
              <ul>
                <li>
                  Max meeting support hours: <strong>5</strong>
                </li>
                <li>
                  Max monthly billable hours: <strong>28</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="package-grid">
          {/* Row */}
          <div></div>
          <div className="title center vert-align">Budget</div>
          <div className="title center vert-align">Basic</div>
          <div className="title center vert-align">Small Business</div>
          <div className="title center vert-align">Growing Business</div>
          <div className="title center vert-align">Elite</div>
          {/* Row */}
          <div className="vert-align">
            <div>
              Historical Bookkeeping
              <br />
              <span>Client inputs data, we reconcile</span>
            </div>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="false"></div>
          {/* Row */}
          <div className="vert-align">
            <div>
              Bookkeeping
              <br />
              <span>We input and reconcile client’s data</span>
            </div>
          </div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row */}
          <div className="vert-align">
            Monthly bank, credit card and loan reconciliations
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row */}
          <div className="vert-align">
            Any needed adjusting journal entries (AJE’s)
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row */}
          <div className="vert-align">
            <div>
              Monthly financial statements
              <br />
              <span>Balance Sheets, Income Statements</span>
            </div>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row */}
          <div className="vert-align">
            <div>
              Unlimited emails
              <br />
              <span>Response time will be charged towards billable hours</span>
            </div>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row */}
          <div className="vert-align">Custom Financials</div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row  */}
          <div className="vert-align">
            Essential document storage, organizing &amp; digital filing
          </div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row  */}
          <div className="vert-align">
            City &amp; State tax returns plus audit support
          </div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row  */}
          <div className="vert-align">Coordinate with client’s CPA</div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row  */}
          <div className="vert-align">Job Costing</div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row  */}
          <div className="vert-align">
            Accounts Payable &amp; Accounts Receivable
          </div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row  */}
          <div className="vert-align">Business License Management</div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row  */}
          <div className="vert-align">Payroll Management</div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row  */}
          <div className="vert-align">1099 Tracking and Processing</div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row  */}
          <div className="vert-align">Industry Specific Reporting</div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row  */}
          <div className="vert-align">
            <div>
              Class location and tracking
              <br />
              <span>Additional fee possible</span>
            </div>
          </div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="false"></div>
          <div className="true">
            <span>&#10003;</span>
          </div>
          {/* Row */}
          <div className="vert-align">Max meeting support hours</div>
          <div className="center true vert-align">.5</div>
          <div className="center true vert-align">1</div>
          <div className="center true vert-align">2</div>
          <div className="center true vert-align">3</div>
          <div className="center true vert-align">5</div>
          {/* Row */}
          <div className="vert-align">Max monthly billable hours</div>
          <div className="center true vert-align">4</div>
          <div className="center true vert-align">5</div>
          <div className="center true vert-align">8</div>
          <div className="center true vert-align">15</div>
          <div className="center true vert-align">28</div>
        </div>
        <br />
        <div className="addon-grid">
          <div className="title center">
            <h3>Add-On Services*</h3>
          </div>
          <div>
            <h3 className="highlight">Basic</h3>
            <ul>
              <li>
                Additional meeting support <br />
                <span>(telephone, Zoom, or in office)</span>
              </li>
              <li>Additional Billable Hours</li>
              <li>Accounts Receivable</li>
              <li>Accounts Payable</li>
              <li>Industry Specific Reporting</li>
              <li>Payroll management</li>
              <li>1099 Tracking and Processing</li>
              <li>Department of Revenue and City Tax Returns</li>
              <li>Business License Management</li>
            </ul>
          </div>
          <div>
            <h3 className="highlight">Premium</h3>
            <ul>
              <li>Audit Support</li>
              <li>Accounting software set up</li>
              <li>Clean Up</li>
              <li>Set Up</li>
              <li>Training</li>
            </ul>
          </div>
          <div className="addon-note">
            <p>* Can be added to any package for an additional fee</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Packages;
