import React from 'react';
import Img from '../layout/images/business.png';

function AboutUs() {
  return (
    <div className='row center-xs about-us'>
      <div className='col-xs-10'>
        <h2>About Us</h2>
        <div className='row start-xs'>
          <div className='col-xs-12 col-md-8'>
            <p>
              I founded my company after seeing how difficult it was to find
              professional bookkeeping services that can provide both the
              expertise and attention small business owners need. Entrepreneurs
              often have to choose between large agencies that treat clients
              like products on an assembly line and freelancers that don't quite
              provide adequate peace of mind.
            </p>

            <p>
              I think there is another way. Just like any national agency my
              team has decades of experience, so you can rest assured that we
              will get it right the first time. Where we stand apart is our
              dedication to making each and every one of our clients feel like
              they are our only client!
            </p>

            <p>
              As a business owner I know how hard you've worked to get your
              business where it is.
            </p>

            <p>
              It’s time to let us do what we do best, so you can get back to
              doing what you love.
            </p>

            <span className='row end-xs'>Kristina</span>
          </div>
          <div className='col-xs-12 col-md-4 center-xs'>
            <img src={Img} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
