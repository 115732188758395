import React from 'react';

function Footer() {
  return (
    <div className='footer'>
      <div className='row center-xs'>
        <span>Bookkeeping Services for the Greater Seattle Area</span>
      </div>
      <div className='row center-xs'>
        <span>
          © 2019 Kristina's Abacus, LLC | Banner Photo by Daniel Schwen
        </span>
      </div>
    </div>
  );
}

export default Footer;
