import React from 'react';
import AIPB from './images/aipb.png';
import QBOA from './images/qboa.png';
import XERO from './images/xero.png';

function Contact() {
  return (
    <div className='row center-xs contact' id='contact'>
      <div className='col-xs-10'>
        <div className='row center-xs'>
          <div className='col-xs-12 center-xs'>
            <h2>Contact Us</h2>
          </div>
        </div>
        <div className='row start-xs between-xs'>
          <div className='col-xs-12 col-md-7'>
            <h4 className='row'>Schedule Your Free Consultation!</h4>
            <form name='contact' method='post'>
              <input type='hidden' name='form-name' value='contact' />
              <label className='row'>
                <input
                  className='col-xs-8'
                  type='text'
                  name='name'
                  placeholder='Name'
                />
              </label>
              <label className='row'>
                <input
                  className='col-xs-8'
                  type='email'
                  name='email'
                  placeholder='Email'
                />
              </label>
              <label className='row'>
                <input
                  className='col-xs-8'
                  type='text'
                  name='phone'
                  placeholder='Phone'
                />
              </label>
              <label className='row'>
                <textarea
                  className='col-xs-12'
                  name='message'
                  placeholder='Tell us about your bookkeeping and accounting needs!'
                ></textarea>
              </label>
              <button className='row' type='submit'>
                Send
              </button>
            </form>
          </div>
          <div className='col-xs-12 col-md-4'>
            <ul>
              <li>
                <h4>Call</h4>
                <p>(425) 658-0030</p>
              </li>
              <li>
                <h4>Email</h4>
                <p>admin@kristinasabacus.com</p>
              </li>
              <li>
                <h4>Visit</h4>
                <p>
                  19105 36th Ave W, Suite 211
                  <br /> Lynnwood, WA 98036
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className='row center-xs'>
          <img
            src={AIPB}
            alt='American Institute of Professional Bookkeepers Member'
          />
          <img src={QBOA} alt='Intuit Certified ProAdvisor' />
          <img src={XERO} alt='XERO Certified Advisor' />
        </div>
      </div>
    </div>
  );
}

export default Contact;
