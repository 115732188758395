import React from 'react';

function Banner() {
  return (
    <div className='row center-xs middle-xs banner'>
      <div className='col-xs-10'>
        <div className='row center-xs'>
          <h1 className='col-xs-12'>
            Professional Accounting &amp; Bookkeeping Services
          </h1>
        </div>
        <div className='row center-xs'>
          <h1 className='col-xs-12 col-sm-4 city'>Seattle, WA</h1>
        </div>
      </div>
    </div>
  );
}

export default Banner;
