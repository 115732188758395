import React from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

function TestimonialOne() {
  return (
    <div className="reviews row center-xs">
      <div className="col-xs-10">
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={80}
          isIntrinsicHeight={false}
          totalSlides={4}
          isPlaying={true}
        >
          <Slider>
            <Slide index={0}>
              <p>
                Our small business here in Seattle has been using Kristina&#39;s
                Abacus for the past 5 years. The bookkeepers at Kristina&#39;s
                Abacus are trustworthy, knowledgeable, and dependable. We
                recommend their services without hesitation!
              </p>
              <p>Josh Cooper, MD - Sound Plastic Surgery, PLLC</p>
            </Slide>
            <Slide index={1}>
              {' '}
              <p>
                &quot;Words cannot begin to express my gratitude for Kristina
                and her dedicated team. She truly goes above and beyond with her
                attentiveness, excellent communication, and brilliant attention
                to detail. I plan on being a life-long client for all of my
                businesses.&quot;
              </p>
              <p>Mariah Gentry – Mariah Gentry Photography, LLC</p>
            </Slide>
            <Slide index={2}>
              {' '}
              <p>
                &quot;We have been working with Kristina and her colleagues
                since our architecture firm was founded six years ago. It has
                been great to have their expertise and organization on board
                from the beginning and I never hesitate to recommend their
                bookkeeping services to other architects.&quot;
              </p>
              <p>Jon Gentry AIA, Partner - goCstudio architecture+design</p>
            </Slide>
            <Slide index={3}>
              {' '}
              <p>
                &quot;Kristina and her team at Kristina’s Abacus have been
                helping me with my book keeping needs since January of 2015.
                Before meeting Kristina, my books were a complete mess. I would
                highly recommend anyone with accounting needs to hire Kristina’s
                Abacus to help get and keep things under control.&quot;
              </p>
              <p>Stacy Eakman - Eakman Construction Company</p>
            </Slide>
          </Slider>
        </CarouselProvider>
      </div>
    </div>
  );
}

export default TestimonialOne;
