import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="navbar row center-xs" id="navbar">
      <div className="col-xs-11">
        <div className="row middle-xs">
          <h1 className="col-xs-9 col-md-5 start-xs start-md">
            {' '}
            <Link to="/">Kristina's Abacus</Link>
          </h1>
          <div className="col-md-7 end-xs">
            <ul className="row menu">
              <li className="col-xs">
                <Link to="/">Home</Link>
              </li>
              <li className="col-xs">
                <Link to="/packages">Packages</Link>
              </li>
              <li className="col-xs">
                <Link to="/about">About Us</Link>
              </li>
              <li className="col-xs">
                <Link to="/our-clients">Testimonials</Link>
              </li>
              {/* <li className="col-xs">
                <Link to="/#">Client Portal</Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
