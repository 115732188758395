import React, { Fragment } from 'react';
import Contact from '../layout/Contact';

function Home() {
  return (
    <Fragment>
      <div className="row center-xs clients">
        <div className="col-xs-10 start-xs">
          <h2 className="center">What our clients have to say...</h2>
          <div className="testimony">
            <p className="client">
              Josh Cooper, MD
              <br />
              Sound Plastic Surgery, PLLC
            </p>
            <p>
              &quot;Our small business here in Seattle has been using
              Kristina&#39;s Abacus for the past 5 years. Kristina and her team
              are extremely responsive to our questions and needs whenever they
              might arise. Our business has benefitted from their meticulous
              bookkeeping skills and working relationship with our accountant.
              The bookkeepers at Kristina&#39;s Abacus are trustworthy,
              knowledgeable, and dependable. We recommend their services without
              hesitation!&quot;
            </p>
          </div>
          <div className="testimony">
            <p className="client">
              Mariah Gentry
              <br />
              Mariah Gentry Photography, LLC
            </p>
            <p>
              &quot;Words cannot begin to express my gratitude for Kristina and
              her dedicated team. She truly goes above and beyond with her
              attentiveness, excellent communication, and brilliant attention to
              detail. She&#39;s always on time with all of my local tax filings
              and book-keeping needs, and I can trust that she has my best
              interest at heart. She&#39;s a life saver and keeps both of my
              businesses organized and up to date with all of the current tax
              and accounting laws. I am so grateful for Kristina&#39;s knowledge
              and can-do attitude! She&#39;s a gem, and I plan on being a
              life-long client for all of my businesses. Thank you much for all
              of your hard work!&quot;
            </p>
          </div>
          <div className="testimony">
            <p className="client">
              Jon Gentry AIA, Partner
              <br />
              goCstudio architecture+design
            </p>
            <p>
              &quot;We have been working with Kristina and her colleagues since
              our architecture firm was founded six years ago. It has been great
              to have their expertise and organization on board from the
              beginning. I never hesitate to recommend their bookkeeping
              services to other architects.&quot;
            </p>
          </div>
          <div className="testimony">
            <p className="client">
              Stacy Eakman
              <br />
              Eakman Construction Company
            </p>
            <p>
              &quot;Kristina and her team at Kristina’s Abacus have been helping
              me with my bookkeeping needs since January of 2015. Before meeting
              Kristina, my books were a complete mess. I had gone years without
              doing my taxes properly. I knew I needed to get things cleaned up,
              but had no idea where to start. I was overwhelmed. <br />
              <br />
              From my first meeting with Kristina, I felt comfort in knowing she
              was on it. Her team went back and rectified my mistakes, helped me
              understand what those mistakes were going to cost, and how we can
              move forward knowing we are not making the same mistakes again.
              Freeing myself from that burden has given me the ability to grow
              my company, knowing the books will be correct and up to date. I
              would highly recommend anyone with accounting needs to hire
              Kristina’s Abacus to help get and keep things under control.&quot;
            </p>
          </div>
        </div>
      </div>
      <Contact />
    </Fragment>
  );
}

export default Home;
