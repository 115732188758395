import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Banner from './components/layout/Banner';
import Footer from './components/layout/Footer';
import Sidebar from './components/layout/Sidebar';
import Home from './components/pages/Home';
import Packages from './components/pages/Packages';
import Clients from './components/pages/Clients';
import About from './components/pages/About';
import NotFound from './components/pages/NotFound';

import 'flexboxgrid';
import './App.scss';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Sidebar />
        <Navbar />
        <Banner />
        <div className="container">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/packages" component={Packages} />
            <Route exact path="/our-clients" component={Clients} />
            <Route exact path="/about" component={About} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
