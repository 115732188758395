import React, { Fragment } from 'react';
import Services from '../layout/Services';
import Contact from '../layout/Contact';
import TestimonialOne from '../layout/TestimonialOne';

function Home() {
  return (
    <Fragment>
      <Services />
      <TestimonialOne />
      <Contact />
    </Fragment>
  );
}

export default Home;
