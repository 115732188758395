import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';

function Sidebar() {
  const [menuOpenState, setMenuOpenState] = useState(false);

  const onClick = () => {
    document.getElementById('navbar').scrollIntoView({ behavior: 'smooth' });
    setMenuOpenState(!menuOpenState);
  };

  const stateChangeHandler = (newState) => setMenuOpenState(newState.isOpen);

  return (
    <Menu
      width={'60%'}
      isOpen={menuOpenState}
      onStateChange={(state) => stateChangeHandler(state)}
    >
      <li className="menu-item">
        <Link to="/" onClick={onClick}>
          Home
        </Link>
      </li>
      <li className="menu-item">
        <Link to="/packages" onClick={onClick}>
          Packages
        </Link>
      </li>
      <li className="menu-item">
        <Link to="/about" onClick={onClick}>
          About Us
        </Link>
      </li>
      <li className="menu-item">
        <Link to="/our-clients" onClick={onClick}>
          Testimonials
        </Link>
      </li>
      {/* <li className="menu-item">
        <Link to="/#" onClick={onClick}>
          Client Portal
        </Link>
      </li> */}
    </Menu>
  );
}

export default Sidebar;
