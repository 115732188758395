import React from 'react';
import AboutUs from '../layout/AboutUs';
import Contact from '../layout/Contact';

function About() {
  return (
    <div>
      <AboutUs />
      <Contact />
    </div>
  );
}

export default About;
