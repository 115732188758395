import React from 'react';

function Services() {
  return (
    <div className="row center-xs services">
      <div className="col-xs-10 col-md-9">
        <div className="row start-xs">
          <div className="col-xs-12 col-md-8">
            <h3>Reliable, Knowledgeable, Personal</h3>

            <p>
              Are you spending more time reconciling your books than growing
              your business?
            </p>

            <p>Do you cross your fingers when filing your taxes?</p>

            <p>If so, it’s time to bring in the professionals.</p>

            <p>
              Every business needs a bookkeeper, but not every bookkeeper cares
              as much about your business as you do. Founded in 2007, Kristina’s
              Abacus specializes in providing bookkeeping and administrative
              services to small businesses in the greater Seattle area. We
              believe that all business owners deserve access to the same level
              of expertise and dedication, no matter their budget.
            </p>

            <p>Every business is big business!</p>
          </div>
          <div className="col-xs-12 first-xs center-xs col-md-4">
            <h2>Services</h2>
            <ul>
              <li>Month, Quarter &amp; Year End Bookkeeping</li>
              <li>State &amp; Local Taxes</li>
              <li>Manage Payroll</li>
              <li>QuickBooks &amp; Xero Setup</li>
              <li>Cleanup Projects</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
